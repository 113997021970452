<template>
  <div>
      <div class="clearfix table-tools">
        <div class="buttons"></div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>操作内容关键词</span>
                </template>
                <a-input allowClear v-model.trim="searchData.title" placeholder="操作内容关键词" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-select style="width:160px" v-model='searchData.wid' @search="handleSearchApp" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
        <div 
            class="messages-list"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <a-card class="messages-card">
                            <h5>【{{item.app_name}}】 {{item.log_time}}</h5>
                            <hr/>
                            <pre>{{item.message}}</pre>
                        </a-card>
                    </a-list-item>
                </a-list>
      </div>                
  </div>
</template>

<script>

import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
// import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'
export default {
  name: 'errReport',
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },
  mixins: [ ranges , authority, tableMixins ],
  directives: { infiniteScroll },
  created() {
    this.getApp()
    // this.searchParams.page=1
    this.getList()
  },
  data() {
      return {
          list:[],
          loading:false,
          appList:[],
          searchParams: {
            "page": 1,
            "per-page": 10,
            "search": {},
            "sort": ''
          },
          searchData: {},
          busy:true
        };
  },
  methods: {
    filterOption(input, option) {
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
     handleInfiniteOnLoad(){
          if(!this.busy){
              this.searchParams.page++
              this.getList()
          }
      },
      async getList() {
        this.loading = true
        // let obj = {}
        // let { searchData } = this
        // if (searchData) {
        //   for (let k in searchData) {
        //     if (k === 'created_at') {
        //       obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
        //       obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
        //     } else {
        //       obj[k] = searchData[k]
        //     }
        //   }
        // }
        // console.log(this.searchParams)
        this.searchParams.search = this.searchData
        let res = await this.$store.dispatch('enterpriseLoggingErrReportAction',{ data: this.searchParams })
        if(this.searchParams.page == 1){
            this.busy = false
            this.list=[];
        }
        if(res.items.length >0){
            this.list = [...this.list,...res.items]
        }
        if(res._meta.totalCount == this.list.length){
            this.busy = true
        }
        this.pageParams = res._meta
        this.loading = false
    },
    async getApp(val){
        await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
        .then((res)=>{
            this.appList = res.data
        })
    },
    handleSearch(val){
        this.getStaff(val)
    },
    handleSearchApp(val){
      this.getApp(val)
    },
  }
}
</script>
<style lang="scss" scoped>
.messages{
  &-card{
    width: 100%;
  }
}
</style>

