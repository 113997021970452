<template>
  <div>
      <div class="clearfix table-tools">
        <div class="buttons"></div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-select style="width:160px" v-model='searchData.wid' @search="handleSearchApp" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="操作类型" >
                        <a-select allowClear v-model="searchData.access_type" placeholder="请选择">
                          <a-select-option v-for="(d, index) of operationTypes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="操作账户" >
                        <a-select allowClear v-model="searchData.employee_id" placeholder="请选择" showSearch  :filterOption="filterOption">
                          <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="操作时间" >
                        <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
          :columns="columns" :dataSource="list">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="opt_content" slot-scope="text">
              <div v-html="text"></div>
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'80px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '应用名称', dataIndex: 'app_name', key: 'app_name'},
  { title: '访问类型', dataIndex: 'access_type', key: 'access_type',align:'center'},
  { title: '访问地址', dataIndex: 'referrer', key: 'referrer'},
  { title: 'User-Agent', width:'500px', dataIndex: 'user_agent', key: 'user_agent'},
  { title: '员工账户', dataIndex: 'employee_name', key: 'employee_name'},
  { title: '学员账户', dataIndex: 'student_name', key: 'student_name'},
  { title: 'IP地址', dataIndex: 'ip', key: '3' },
  { title: '操作时间', dataIndex: 'created_at', key: '4' },
]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'operation',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      operationTypes: [{ label: 'H5', value: 'h5' },{ label: 'QYEX-H5', value: 'qywx-h5' },{ label: 'WEB', value: 'web' }],
      accounts: [],
      appList: [],
      staffList: [],

      searchData: {},

      visible: false,
      refundVisible: false,
      exportLoading: false,
      authType:['orginization','logging'],
    }
  },
  mixins: [ ranges , authority, tableMixins ],
  created() {
    this.getApp()
    this.getStaff()
  },

  methods: {
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    async getApp(val){
        await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
        .then((res)=>{
            this.appList = res.data
        })
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('enterpriseLoggingAccessAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleSearch(val){
        this.getStaff(val)
    },
    handleSearchApp(val){
      this.getApp(val)
    },
  }
}
</script>