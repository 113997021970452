<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>操作日志</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">操作日志</span>
          <operation v-if="activeKey === '1'"/>
        </a-tab-pane>

        <a-tab-pane key="2">
          <span slot="tab">错误日志</span>
          <errReport v-if="activeKey === '2'"/>
        </a-tab-pane>

        <a-tab-pane key="3">
          <span slot="tab">访问日志</span>
          <accessReport v-if="activeKey === '3'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import operation from './operation'
import errReport from './errReport'
import accessReport from './access'
export default {
  name: 'Contract',
  components: {
    operation,
    accessReport,
    errReport
  },
  data() {
    return {
      activeKey: '1',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
